<template>
  <div class="adviceBox">
    <!-- tabs标签页 -->
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="治疗模式组套" name="1">
          <div class="displya_flex">
            <!-- 左侧 -->
            <div style="width: 25%">
              <div class="displya_flex" style="margin-bottom: 10px">
                <!-- 头部 -->
                <div style="width: 50%">
                  <el-input
                    v-model="searchs"
                    clearable
                    placeholder="请输入搜索信息"
                  >
                    <template #suffix>
                      <div @click="search" class="searchcss">
                        <i class="fa fa-search"></i>
                      </div>
                    </template>
                  </el-input>
                </div>
                <div>
                  <el-button
                    class="color-main"
                    type="primary"
                    @click="modParient(1)"
                  >
                    <i class="fa fa-plus-circle"></i>新增组套
                  </el-button>
                </div>
              </div>
              <!-- 表格 -->
              <div class="tables">
                <el-table
                  v-loading="loading"
                  :data="tableData"
                  stripe
                  border
                  max-height="500px"
                  @row-click="rowclick"
                  style="min-width: 100%"
                >
                  <el-table-column prop="set_name" label="名称" />
                  <el-table-column fixed="right" label="操作" width="180">
                    <template #default="scope">
                      <el-button
                        type="text"
                        size="small"
                        @click="deatilsClick(scope.row.id)"
                      >
                        <span class="txfonts icons">添</span>
                      </el-button>
                      <el-button
                        type="text"
                        size="small"
                        @click="modParient(2, scope.row)"
                      >
                        <span class="txfonts modify">改</span>
                      </el-button>
                      <el-popconfirm
                        title="是否确定删除?"
                        @confirm="del(scope.row.id)"
                      >
                        <template #reference>
                          <el-button type="text" size="small">
                            <span class="txfonts del">删</span>
                          </el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="pagination-box">
                <div class="displya_flex">
                  <div></div>
                  <div>
                    <el-pagination
                      @current-change="currentings"
                      small
                      background
                      layout="prev, pager, next"
                      :total="total"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- 右侧 -->

            <div class="rigthc_table">
              <!-- 顶部 -->
              <div class="rigth_top">
                <el-alert
                  title="左侧选中组套所包含项目"
                  type="warning"
                  show-icon
                  :closable="false"
                />
              </div>
              <!-- 表格 -->
              <div class="tables">
                <el-table
                  :data="rightableData"
                  stripe
                  max-height="500px"
                  border
                  style="min-width: 100%"
                >
                  <el-table-column prop="dict_catalog_type_name" label="类别" />
                  <el-table-column prop="name" label="名称" />
                  <el-table-column prop="medi_spec" label="规格" />
                  <el-table-column label="数量">
                    <template #default="scope">
                      <el-input-number
                        @change="changenum(scope.row)"
                        v-model="scope.row.num"
                        class="mx-4"
                        :min="1"
                        :max="99"
                        size="small"
                        controls-position="right"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作" width="180">
                    <template #default="scope">
                      <el-popconfirm
                        title="是否确定删除?"
                        @confirm="rigthdel(scope.row.id)"
                      >
                        <template #reference>
                          <el-button type="text" size="small">
                            <span class="txfonts del">删</span>
                          </el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="pagination-box">
                <el-pagination
                  v-model:currentPage="pageData.currentPage"
                  v-model:page-size="pageData.pageSize"
                  :page-sizes="[10, 20, 50, 100]"
                  :background="true"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalSum"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
            </div>

            <!-- 新增弹窗 -->
            <div>
              <BaseDialog
                title="新增组套项目"
                :isshow="show"
                @handleShow="
                  (val) => {
                    show = val
                  }
                "
                width="70%"
                height="auto"
              >
                <!-- 内容 -->
                <div class="displya_flex">
                  <!-- 左侧 -->
                  <div style="width: 40%">
                    <div class="displya_flex" style="margin: 8px 0">
                      <!-- 头部 -->

                      <div style="width: 90%">
                        <el-radio-group v-model="radio2" @change="Radios">
                          <el-radio-button label="药品" />
                          <el-radio-button label="耗材" />
                          <el-radio-button label="治疗项" />
                        </el-radio-group>
                      </div>
                      <!-- 右侧 -->
                      <div>
                        <el-input
                          v-model="addsearchs"
                          clearable
                          placeholder="请输入搜索信息"
                        >
                          <template #suffix>
                            <div @click="addsearch" class="searchcss">
                              <i class="fa fa-search"></i>
                            </div>
                          </template>
                        </el-input>
                      </div>
                    </div>

                    <!-- 表格 -->
                    <div class="tables">
                      <el-table
                        v-loading="dataListLoading"
                        :data="Dialogtable"
                        stripe
                        border
                        max-height="500px"
                        style="width: 100%"
                      >
                        <el-table-column prop="name" label="名称" />
                        <el-table-column prop="medi_spec" label="规格" />
                        <el-table-column prop="sell_price" label="售价" />
                        <el-table-column fixed="right" label="新增到">
                          <template #default="scope">
                            <div
                              class="arrow-right"
                              @click="arrowtigth(scope.row.id)"
                            >
                              <i class="fa fa-long-arrow-right"></i>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div class="pagination-box">
                      <div class="displya_flex">
                        <div></div>
                        <div>
                          <el-pagination
                            @current-change="addcurrent"
                            small
                            background
                            layout="prev, pager, next"
                            :total="totals"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 右侧 -->

                  <div
                    class="rigthc_table"
                    style="margin: 8px 10px; width: calc(100% - 30%)"
                  >
                    <!-- 顶部 -->
                    <div class="rigth_top">
                      <el-alert
                        title="左侧选中组套所包含项目"
                        type="warning"
                        show-icon
                        :closable="false"
                      />
                    </div>
                    <!-- 表格 -->
                    <div class="tables">
                      <el-table
                        v-loading="Dialoading"
                        :data="rightableData"
                        stripe
                        border
                        max-height="500px"
                        style="min-width: 100%"
                      >
                        <el-table-column
                          prop="dict_catalog_type_name"
                          width="80"
                          label="类别"
                        />
                        <el-table-column prop="name" label="名称" width="100" />
                        <el-table-column prop="medi_spec" label="规格" />
                        <el-table-column prop="num" label="数量">
                          <template #default="scope">
                            <el-input-number
                              @change="changenum(scope.row)"
                              v-model="scope.row.num"
                              class="mx-4"
                              :min="1"
                              :max="99"
                              size="small"
                              controls-position="right"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                          <template #default="scope">
                            <el-popconfirm
                              title="是否确定删除?"
                              @confirm="rigthdel(scope.row.id)"
                            >
                              <template #reference>
                                <el-button type="text" size="small">
                                  <span class="txfonts del">删</span>
                                </el-button>
                              </template>
                            </el-popconfirm>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <div class="pagination-box">
                      <el-pagination
                        v-model:currentPage="pageData.currentPage"
                        v-model:page-size="pageData.pageSize"
                        :page-sizes="[10, 20, 50, 100]"
                        :background="true"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageData.totalSum"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                      ></el-pagination>
                    </div>
                  </div>
                </div>
              </BaseDialog>
            </div>
            <!-- 新增或修改收费组套弹窗 -->
            <div>
              <BaseDialog
                :title="addIndex == 1 ? '新增收费组套' : '修改收费组套'"
                :isshow="adshow"
                @handleShow="
                  (val) => {
                    adshow = val
                  }
                "
                width="27%"
                height="auto"
              >
                <!-- 内容 -->
                <el-form
                  ref="ruleFormRef"
                  :model="ruleForm"
                  :rules="rules"
                  class="demo-ruleForm"
                  :size="formSize"
                  label-width="80px"
                >
                  <el-form-item label="适用范围" prop="scope_application">
                    <el-select
                      v-model="ruleForm.scope_application"
                      :disabled="disabled"
                      class="m-2"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in scope"
                        :key="index"
                        :label="item.use_fanwei"
                        :value="item.use_fanwei"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所属类型">
                    <el-select
                      v-model="ruleForm.belong_type"
                      class="m-2"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in Belongs"
                        :key="index"
                        :label="item.belong_type"
                        :value="item.belong_type"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="透析方式">
                    <el-select
                      v-model="ruleForm.set_mode"
                      class="m-2"
                      placeholder="请选择"
                    >
                      <el-option
                        @click="setmode(item.name)"
                        v-for="item in dictList[100000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="组套名称" prop="set_name">
                    <el-input v-model="ruleForm.set_name" />
                  </el-form-item>
                  <el-form-item label="医保类型">
                    <el-select
                      v-model="ruleForm.medical_type"
                      class="m-2"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in dictList[103000000]"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
                <!-- 下划线 -->
                <el-divider />
                <!-- 底部按钮 -->
                <div class="displya_flex">
                  <div></div>
                  <el-row class="mb-4">
                    <el-button @click="adshow = false">取消</el-button>
                    <el-button type="primary" @click="confirm">确定</el-button>
                  </el-row>
                </div>
              </BaseDialog>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="血透器组套" name="2">
          <hemodia ref="hemodia"></hemodia>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, ref } from 'vue'
import BaseDialog from '@/components/Dialog/index.vue'
import hemodia from './hemodialysis.vue'
import { gitDictList } from '@/utils/tool'
import { ElMessage } from 'element-plus'
import service from '@/utils/request'
export default {
  components: {
    BaseDialog,
    hemodia,
  },
  setup() {
    const state = reactive({
      activeName: '1',
      scope: [],
      Belongs: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      addcurrent: 1,
      currentings: 1,
      total: null,
      totals: null,
      dataListLoading: true,
      dictList: {},
      sid: null,
      radio2: '药品',
      dict_catalog_type: '160000000',
      show: false,
      adshow: false,
      loading: true,
      Dialoading: true,
      ruleForm: {},
      addIndex: null,
      disabled: false,
      searchs: '',
      addsearchs: '',
      tableData: [],
      Dialogtable: [],
      rightableData: [],
    })
    onMounted(() => {
      initData()
      tobody()
      project()
    })
    // 获取字典
    const initData = async () => {
      state.dictList = await gitDictList(['103000000', '100000000'])
    }
    const hemodia = ref()
    // tabs切换
    const handleClick = () => {
      if (state.activeName == '2') {
        hemodia.value.tobody()
      } else {
        tobody()
      }
    }
    // 左侧列表点击
    const rowclick = (row) => {
      state.sid = row.id
      rightbody(row.id)
    }
    // 新增组套
    const confirm = async () => {
      state.ruleForm.type = '1'
      if (!state.ruleForm.id) {
        let res = await service.post('/api/setup/setSave', state.ruleForm)
        if (res.code === 0) {
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
        }
      } else {
        let res = await service.post('/api/setup/setEdit', state.ruleForm)
        if (res.code === 0) {
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
        }
      }
      tobody()
      state.adshow = false
    }
    // 搜索
    const search = () => {
      tobody()
    }

    // 新增搜索
    const addsearch = () => {
      project()
    }

    // 获取左侧列表
    const tobody = async () => {
      state.loading = true
      let data = {
        type: state.activeName,
        searche: state.searchs,
        current: state.currentings,
        size: 20,
      }
      let res = await service.post('/api/setup/getList', data)
      if (res.code === 0) {
        state.loading = false
        if (res.data.total > 0) {
          state.tableData = res.data.list
          state.total = res.data.total
          rightbody(res.data.list[0].id)
          state.sid = res.data.list[0].id
        } else {
          state.tableData = []
        }
      }
    }
    // 右侧表格列表
    const rightbody = async (id) => {
      state.Dialoading = true
      let data = {
        sid: id,
        current: state.pageData.currentPage,
        size: state.pageData.pageSize,
      }
      let res = await service.post('/api/setup/getPriject', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.rightableData = res.data.list
          state.pageData.totalSum = res.data.total
        } else {
          state.rightableData = []
        }
      }
      state.Dialoading = false
    }
    // 左侧删除
    const del = async (id) => {
      let res = await service.post('/api/setup/setDelete', { id: id })
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        tobody()
      }
    }
    // 单选
    const Radios = () => {
      if (state.radio2 == '药品') {
        state.dict_catalog_type = '160000000'
      } else if (state.radio2 == '耗材') {
        state.dict_catalog_type = '164000000'
      } else {
        state.dict_catalog_type = '161000000'
      }
      project()
    }
    // 获取组套项目
    const project = async () => {
      state.dataListLoading = true
      let data = {
        current: state.addcurrent,
        size: 20,
        type: '1',
        spell: state.addsearchs,
        dict_catalog_type: state.dict_catalog_type,
      }
      let res = await service.post('/api/setup/addProjectShow', data)
      if (res.code === 0) {
        if (res.data.total > 0) {
          state.Dialogtable = res.data.records
          state.totals = res.data.total
        } else {
          state.Dialogtable = []
        }
      }
      state.dataListLoading = false
    }
    // 添加组套项目
    const arrowtigth = async (id) => {
      let data = {
        sid: state.sid,
        dreg_id: id,
        num: 1,
      }
      let res = await service.post('/api/setup/addProject', data)
      if (res.code === 0) {
        ElMessage({
          message: '添加成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 右侧表格条数改变时
    const handleSizeChange = (e) => {
      state.pageData.pageSize = e
      rightbody(state.sid)
    }
    // 右侧表格页数改变时
    const handleCurrentChange = (e) => {
      state.pageData.currentPage = e
      rightbody(state.sid)
    }
    // 右侧表格改变数量时
    const changenum = async (row) => {
      let data = {
        sid: state.sid,
        id: row.id,
        num: row.num,
      }
      let res = await service.post('api/setup/projectEdit', data)
      if (res.code === 0) {
        ElMessage({
          message: '修改成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 新增左侧列表页数改变时
    const addcurrent = (e) => {
      state.addcurrent = e
      project()
    }
    // 左侧页数改变时
    const currentings = (e) => {
      state.currentings = e
      tobody()
    }
    // 右侧表格删除
    const rigthdel = async (id) => {
      let data = {
        sid: state.sid,
        id: id,
      }
      let res = await service.post('/api/setup/projectDelete', data)
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        rightbody(state.sid)
      }
    }
    // 透析方式选择
    const setmode = (val) => {
      state.ruleForm.set_name = val
    }
    // 新增组套项目
    const deatilsClick = (id) => {
      state.sid = id
      state.show = true
    }
    // 表单校验规则
    const rules = reactive({
      set_name: [
        {
          required: true,
          message: '此项必填,请输入',
        },
      ],
      scope_application: [
        {
          required: true,
          message: '此项必填,请输入',
        },
      ],
    })
    // 新增或修改收费座套
    const modParient = async (index, row) => {
      state.scope = []
      state.Belongs = []
      state.ruleForm = {}
      let res = await service.post('/api/setup/setAdd', { type: '1' })
      if (res.code === 0) {
        state.scope.push(res.data.fanwei)
        state.Belongs.push(res.data.moshi)
      }
      if (index == 2) {
        state.disabled = true
        state.ruleForm = JSON.parse(JSON.stringify(row))
      } else {
        state.disabled = false
      }
      state.addIndex = index
      state.adshow = true
    }
    return {
      ...toRefs(state),
      deatilsClick,
      modParient,
      Radios,
      rules,
      initData,
      tobody,
      handleClick,
      rowclick,
      rightbody,
      confirm,
      hemodia,
      search,
      addsearch,
      del,
      project,
      arrowtigth,
      currentings,
      addcurrent,
      handleSizeChange,
      handleCurrentChange,
      rigthdel,
      changenum,
      setmode,
    }
  },
}
</script>

<style scoped lang="scss">
.adviceBox {
  height: 728px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}
.displya_flex {
  display: flex;
  justify-content: space-between;
  .searchcss {
    cursor: pointer;
  }
}
.icons {
  background: rgba(49, 102, 174, 0.3);
  color: #3166ae;
}
.rigthc_table {
  margin-left: 20px;
  width: calc(100% - 25%);
  &:deep(.el-alert--warning.is-light) {
    background-color: rgba(49, 102, 174, 0.1);
    color: #3166ae;
  }
  .rigth_top {
    margin-bottom: 5.5px;
  }
}
.arrow-right {
  width: 21px;
  height: 21px;
  background: rgba(112, 210, 64, 0.2);
  opacity: 1;
  color: #3fbd00;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
}
.demo-ruleForm {
  margin-top: 20px;
}
.modify {
  background-color: #3166ae;
}
.del {
  background-color: #ffecec;
  color: #ff6b6b;
  font-weight: 700;
}
.el-radio-group {
  flex-wrap: nowrap;
}
</style>
